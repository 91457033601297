.ahorcado_integrantes_ccont{
    width: 90%;
}

.ahorcado_integrantes {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
}
.preguntaIntegrantes {
    font-size: 5rem;
    color: #ffffff;
    background-color: rgb(42 120 0 / 50%);
    padding: 10px 30px;
    border-radius: 15px;
    text-align: center;
}
.ahorcado-eligeIntegrantes {
    display: flex;
    justify-content: space-around;
    margin-top: 5rem;
}

.ahorcado-botonIntegrantes {
    display: inline-block;
    padding: 15px 25px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #904190;
    border: none;
    border-radius: 15px;
    font-size: 1.8rem;
    box-shadow: 0 9px #999;
    width: 20rem;
    font-weight: bold;
}

.ahorcado-botonIntegrantes:hover {
    background-color: #b542b5
}

.ahorcado-botonIntegrantes:active {
    background-color: #b542b5;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}


@media only screen and (max-width: 319px) {
    .ahorcado_integrantes_ccont {
        width: 70%;
    }
    .preguntaIntegrantes {
        font-size: 3rem;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .ahorcado_integrantes_ccont {
        width: 70%;
    }
    .preguntaIntegrantes {
        font-size: 3rem;
    }
}

@media only screen and (min-width: 480px) and (max-width: 599px) {

}

@media only screen and (min-width: 600px) and (max-width: 799px) {

}

@media only screen and (min-width: 800px) and (max-width: 992px) {

}

@media only screen and (min-width: 993px) and (max-width: 1023px) {

}

@media only screen and (min-width: 1024px) {

}