
.buttonCont{
    display: inline-block;
    padding: 15px 25px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    border: none;
    border-radius: 15px;
    font-size: 1.8rem;
    width: 20rem;
    font-weight: bold;
}

.btnColorGreen{
    background-color: #329b56;
    box-shadow: 0 9px #999;
    color: #fff;
}

.btnColorGreen:hover {
    background-color: #37b150;
}

.btnColorGreen:active {
    background-color: #37b150;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}