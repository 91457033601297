.icon-bi{
    font-size: 15px;
}

.container_buttonHome{
    width: 5rem;
    height: 5rem;
    background-color: #904190;
    margin: 5px 15px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    line-height: 0;
    display: flex;
    cursor: pointer;
}