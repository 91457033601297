.gamewin_modal {
    position: absolute;
    background-color: rgb(0 0 0 / 19%);
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gamewin_modal-body{
    width: 55%;
    height: 30%;
    background-color: white;
    position: relative;
    padding: 30px 0px;
    border-radius: 10%;
}

.gamewin__Message{
    font-size: 4.5rem;
    text-align: center;
    color: rgb(10, 110, 7);
    
}

.gamewin_constBtn{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 3rem;
}

@media only screen and (max-width: 319px) {
    .gamewin_modal{
        width: 100%;
    }
    .gamewin_modal-body{
        width: 75%;
        height: 35%;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .gamewin_modal{
        width: 100%;
    }
    .gamewin_modal-body{
        width: 90%;
        height: 40%;
    }
}

@media only screen and (min-width: 480px) and (max-width: 599px) {

}

@media only screen and (min-width: 600px) and (max-width: 799px) {
    .gamewin_modal-body{
        width: 100%;
    }
}

@media only screen and (min-width: 800px) and (max-width: 992px) {
    .gamewin_modal-body{
        width: 100%;
    }
}

@media only screen and (min-width: 993px) and (max-width: 1023px) {

}

@media only screen and (min-width: 1024px) {

}

@media only screen and (min-width: 1280px) {
    .gamewin_modal-body{
        width: 60%;
        height: 38%;
    }
}