.Footer{
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.Footer-iconHome.icon-bi{
    font-size: 3rem;
    color: #ffffff;
}


@media only screen and (max-width: 319px) {

}

@media only screen and (min-width: 320px) and (max-width: 479px) {

}

@media only screen and (min-width: 480px) and (max-width: 599px) {

}

@media only screen and (min-width: 600px) and (max-width: 799px) {

}

@media only screen and (min-width: 800px) and (max-width: 992px) {

}

@media only screen and (min-width: 993px) and (max-width: 1023px) {

}

@media only screen and (min-width: 1024px) {

}