.ahorcado_eligePalabra {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* height: 100%; */
}

.eligePalabra_texto {
    font-size: 3rem;
    margin-bottom: 2rem;
    color: #904190;
    font-weight: bold;

}

.eligePalabra_inputText {
    height: 5rem;
    width: 45%;
    background-color: #f0f8ff;
    border: none;
    border-radius: 15px;
    padding: 0 10px;
    font-size: 2rem;
    color: #329b56;
    box-shadow: -2px 4px 6px 0px #616161;
}

.eligePalabra_inputText:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}

.eligePalabra_inputText:hover {
    outline: -webkit-focus-ring-color auto 0px;
}

.length_word{
    margin-top: 1rem;
    margin-bottom: 4rem;
    font-size: 1.5rem;
    color: #904190;
    font-weight: bold;
    display: flex;
    justify-content: end;
}

.eligePalabra_mensajeError {
    font-size: 1.5rem;
    color: #a94646;
    font-weight: bold;
    margin-top: 1rem;
}

@media only screen and (max-width: 319px) {
    .eligePalabra_inputText {
        width: 80%;
    }
}

@media only screen and (max-width: 420px) {
    .eligePalabra_inputText {
        width: 80%;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {

}

@media only screen and (min-width: 480px) and (max-width: 599px) {

}

@media only screen and (min-width: 600px) and (max-width: 799px) {

}

@media only screen and (min-width: 800px) and (max-width: 992px) {

}

@media only screen and (min-width: 993px) and (max-width: 1023px) {

}

@media only screen and (min-width: 1024px) {

}