* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
}

html {
    /* 
        1rem es igual a 16 px    
        Pero con ponerle al html font-size: 62.5%;
        decimos que por cada 1rem es 10px
    */
    font-size: 62.5%;
    font-family: cursive;
}

.ahorcado_container {
    display: flex;
    justify-content: center;
    background-color: #2e2e2e;
    height: auto;
    overflow: auto;
    min-height: 100%;
}

.title {
    font-size: 4rem;
    text-align: center;
    color: #9df35a;
    text-shadow: 0 0 7px #044e02;
}

.ahorcado_view {
    width: 50%;
    /* height: 100%; */
    background-image: url("../../Assets/Media/fondo.avif");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: static;
    
    /* padding: 15px 10px; */
}

.ahorcado_view-fondo {
    background-color: rgb(255 255 255 / 70%);
    /* border-radius: 15px; */
    position: relative;
    padding: 20px 0;

    /* min-width: 100%;
    overflow: auto; */
    height: 100%;
}


@media only screen and (max-width: 319px) {
    .ahorcado_view {
        width: 100%;
    }
    .title {
        font-size: 3rem;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .ahorcado_view {
        width: 100%;
    }
    .title {
        font-size: 3rem;
    }
}

@media only screen and (min-width: 480px) and (max-width: 599px) {
    .ahorcado_view {
        width: 100%;
    }
    .title {
        font-size: 3rem;
    }
}

@media only screen and (min-width: 600px) and (max-width: 799px) {
    .ahorcado_view {
        width: 80%;
    }
}

@media only screen and (min-width: 800px) and (max-width: 992px) {
    .ahorcado_view {
        width: 75%;
    }
}

@media only screen and (min-width: 993px) and (max-width: 1023px) {

}

@media only screen and (min-width: 1024px) {

}
