.TwoPlayer{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 90%;
    flex-direction: column;
}


@media only screen and (max-width: 319px) {
    .TwoPlayer {
        height: 66%;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .TwoPlayer {
        height: 70%;
    }
}

@media only screen and (min-width: 480px) and (max-width: 599px) {

}

@media only screen and (min-width: 600px) and (max-width: 799px) {

}

@media only screen and (min-width: 800px) and (max-width: 992px) {

}

@media only screen and (min-width: 993px) and (max-width: 1023px) {

}

@media only screen and (min-width: 1024px) {

}