.adivinaAdivina_abecedario {
    display: flex;
    justify-content: center;
}

.abecedario_container {
    width: 60%;
    margin-top: 5rem;
    margin-bottom: 5rem;
    text-align: center;
}

.abecedario_button {
    font-size: 2rem;
    background-color: #000000;
    color: white;
    cursor: pointer;
    border: 0px;
    border-radius: 5px;
    font-weight: bold;
    margin: 2px 2px;
    width: 4rem;
    height: 3.5rem
}

.abecedario_button:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
}

.abecedario_button:hover {
    background-color: #09a000;
}

.abecedario_button:disabled {
    background-color: #da0707;
    cursor: auto;
}


@media only screen and (max-width: 319px) {
    .abecedario_container {
        width: 85%;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .abecedario_container {
        width: 87%;
    }
}

@media only screen and (min-width: 480px) and (max-width: 599px) {

}

@media only screen and (min-width: 600px) and (max-width: 799px) {

}

@media only screen and (min-width: 800px) and (max-width: 992px) {

}

@media only screen and (min-width: 993px) and (max-width: 1023px) {

}

@media only screen and (min-width: 1024px) {

}
